import React from "react";
import "./styles.css";
import * as apiBook from "../../api/book_airline";
import * as apiUser from "../../api/user";
import Customers from './customer';
import Flight from './flight';
import Delay from './delay';
import NumericInput from '../../components/inputtext_number'
import { Select, Button, Modal, DatePicker } from 'antd';
const { Option } = Select;
var moment = require('moment');
const dateFormat = 'DD/MM/YYYY';
const dateFormatList = ['DD/MM/YYYY', `YYYY-MM-DDTHH:mm:ss.SSSZ`];

export default class detail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      data: { customers: [] },
      payDate: null,
      users: [],
      id: 0,
      staffId: 0,
      customer: {gender: true},
      isAdd: true,
      status: "STATUS_WAIT_HANDLE",
      valueType: "TYPE_WEB",
      paymentMethod: "PAYMENT_METHOD_OFFICE",
      itineraryType: 1,
      delayInsuranceId: 0,
      delayInsurance: null,
      loadingButton: false
    };
  }

  componentDidMount() {
    if (this.props.match !== undefined) {
      const { id } = this.props.match.params;
      this.setState({id, isAdd: false},()=>{this.getBookById();});
    }
    apiUser.getAsign().then(response => {
      if (response !== undefined) {
        var users = response;
        var user = {id: 0, name: "-- chọn --"};
        users.splice(0, 0, user);
        this.setState({
          users,
        });
      }
    });
  }

  getBookById=()=>{
    const { id } = this.state;
    apiBook.getBookById(id).then(response => {
      if (response !== undefined) {
        // var customerPrice = 0;
        // response.customers.map((item)=> (
        //   customerPrice += item.priceDeparture + item.priceReturn
        // ))
        // var ticketPrice = response.departurePriceTotal + response.returnPriceTotal;
        var fee = response.departureFeeTotal + response.returnFeeTotal
        // var price = ticketPrice + customerPrice;

        this.setState({
          data: response,
          name: response.contact.firstName,
          lastName: response.contact.lastName,
          phone: response.contact.phone,
          email: response.contact.email,
          address: response.contact.address,
          price: response.totalPrice,
          valueType: response.typeBook,
          fee,
          staffId: response.staffId,
          note: response.note,
          status: response.status,
          itineraryType: response.itineraryType,
          paymentMethod: response.paymentMethod,
          payDate: response.payDate,
          bank: response.bank,
          departureAirportCode: response.departureAirportCode,
          code: response.itineraryType === 1 ? response.departureCode : `${response.departureCode} ${response.returnCode}`,
          id,
          delayInsuranceId: response.delayInsuranceId,
          delayInsurance: response.delayInsurance
        });
      }
    });
  }

  handleChangeTitle = (event, name) => {
    this.setState({ name: event.target.value });
  };

  handleChangeLastName = (event) => {
    this.setState({ lastName: event.target.value });
  };

  handleChangePhone = event => {
    this.setState({ phone: event.target.value });
  };

  handleChangeEmail = event => {
    this.setState({ email: event.target.value });
  };

  handleChangeAddress = event => {
    this.setState({ address: event.target.value });
  };

  handleChangeTotalPrice = value => {
    this.setState({ price: value });
  };

  handleChangeFee = value => {
    this.setState({ fee: value });
  };

  handleChangeNote = event => {
    this.setState({ note: event.target.value });
  };

  handleChangeCode = event => {
    this.setState({ code: event.target.value });
  };

  handleChangeBank = event => {
    this.setState({ bank: event.target.value });
  };

  handleChangeDatePayment = (date, dateString) => {
    if(date != null)
      this.setState({ date: date.format("YYYY-MM-DD'T'HH:mm:ss.SSS") });
  };

  handleChangeBank = event => {
    this.setState({ bank: event.target.value });
  };

  handleChangeUser = event => {
    this.setState({ staffId: event });
  };

  handleChangeStatus = event => {
    this.setState({ status: event});
  };

  handleChangeMethod = event => {
    this.setState({ paymentMethod: event});
  };

  handleMenuClick =(e) => {
    this.setState({valueType: e})
  }

  onChangeGender = e => {
    this.setState({
      gender: e.target.value,
    });
  };

  editBook=()=>{
    const { isAdd, name, phone, email, address, valueType, status, paymentMethod, code, itineraryType, lastName, staffId, note, id, data,
      departureAirportCode, destinationAirportCode, departureDate, departureLandDate, returnDate, returnLandDate, departureAirlineCode, departurePriceTotal, 
      departureFeeTotal, departureFlightNumber,returnAirlineCode, returnPriceTotal,returnFeeTotal, returnFlightNumber, bank, payDate} = this.state;

      if(name===""){
        alert("Tên không để trống")
        return;
      }

      if(lastName===undefined || lastName===""){
        alert("Họ không để trống")
        return;
      }
  
      if(phone===undefined || phone===""){
        alert("Số điện thoại không để trống")
        return;
      }
  
      if(email===undefined || email===""){
        alert("Email không để trống")
        return;
      }

      if(departureAirportCode===undefined || departureAirportCode===""){
        alert("Chưa có thông tin chuyến bay")
        return;
      }

      if(data.customers.length === 0){
        alert("Chưa có thông tin khách hàng")
        return;
      }

    var contact = {firstName : name, phone, email, address, contactEmail: email, lastName}
    var departureCode = ""
    var returnCode = ""
    if(itineraryType === 2){
      const codes = code.split(' ');
      departureCode = codes[0].trim()
      returnCode = departureCode
      if(codes[1] !== undefined)
        returnCode = codes[1].trim()
    }else{
      departureCode = code.trim()
    }

    if(isAdd){
      var obj = {status, paymentMethod, departureCode, returnCode, contactInfo: contact, typeBook: valueType, staffId, note, customers: data.customers,
        departureAirportCode, destinationAirportCode, departureDate, departureLandDate, returnLandDate, returnDate, departureAirlineCode, departurePriceTotal, 
        departureFeeTotal, departureFlightNumber,returnAirlineCode, returnPriceTotal,returnFeeTotal, returnFlightNumber, itineraryType, bank, payDate }
      apiBook.createBook(obj).then(response => {
        if (response !== undefined) {
          alert(response.message);
        }
      });
    }else{
      var obj = {status, paymentMethod, departureCode, returnCode, contactInfo: contact, typeBook: valueType, staffId, note, bank, payDate}
      apiBook.editBook(id, obj).then(response => {
        if (response !== undefined) {
          alert(response.message);
          this.getBookById()
        }
      });
    }
  }

  customersRep=(customers)=>{
    var data = this.state.data
    data.customers = customers
    this.setState({data})
  }

  flightRep=(obj)=>{
    this.setState({ 
      departureAirportCode: obj.departureAirportCode, 
      destinationAirportCode: obj.destinationAirportCode, 
      departureDate: obj.departureDate, 
      departureLandDate: obj.departureLandDate, 
      returnLandDate: obj.returnLandDate, 
      returnDate: obj.returnDate, 
      departureAirlineCode: obj.departureAirlineCode, 
      departurePriceTotal: obj.departurePriceTotal, 
      departureFeeTotal: obj.departureFeeTotal, 
      departureFlightNumber: obj.departureFlightNumber,
      returnAirlineCode: obj.returnAirlineCode, 
      returnPriceTotal: obj.returnPriceTotal,
      returnFeeTotal: obj.returnFeeTotal, 
      returnFlightNumber: obj.returnFlightNumber,
      itineraryType: obj.itineraryType
    })
  }

  handleChangeBirthday = (date, dateString) => {
    if(date != null)
      this.setState({ payDate: date.format(dateFormatList[1]) });
  };

  issueTheTicket=()=>{
    this.setState({
      loadingButton: true
    })
    const {data} = this.state;
    apiBook.issueTheTicketNoCode(data.code).then(response => {
      if (response !== undefined) {
        alert(response.message);
      }
      this.setState({
        loadingButton: false
      })
    }).catch(err =>{
      this.setState({
        loadingButton: false
      })
      if (err.response.status === 401) {
        alert(err.response.data.message)
        localStorage.clear();
      }else if (err.response.status === 400) {
        alert(err.response.data.message)
      }else
      alert(err.message)
    
    });
  }

  render() {
    const { isAdd, name, payDate, lastName, phone, email, address, price, fee, note, valueType, code, 
      bank, status, paymentMethod, users, staffId, data , id, itineraryType, 
      delayInsuranceId, delayInsurance, loadingButton} = this.state;
      console.log(data)
    return (
      <div>
        <h3>THÔNG TIN MÃ ĐƠN HÀNG {data.code}</h3>
        {/* <Button type="primary" loading={loadingButton} onClick={this.issueTheTicket}> Xuất vé thanh toán ngay </Button> */}
        <div className="row mt-3 mr-2">
          <div className="col-6">
            <div className="row mr-2">
              <h6 className="col-3">Tên:</h6>
              <input
                type="text"
                className="form-control col-9"
                value={name}
                disabled
                onChange={event => this.handleChangeTitle(event, "name")}
              />
            </div>

            <div className="row mt-3 mr-2">
              <h6 className="col-3">Họ:</h6>
              <input
                type="text"
                className="form-control col-9"
                value={lastName}
                disabled
                onChange={event => this.handleChangeLastName(event)}
              />
            </div>

            <div className="row mt-3 mr-2">
              <h6 className="col-3">Số điện thoại:</h6>
              <input
                type="text"
                className="form-control col-9"
                value={phone}
                disabled
                onChange={event => this.handleChangePhone(event)}
              />
            </div>

            <div className="row mt-3 mr-2">
              <h6 className="col-3">Email:</h6>
              <input
                type="text"
                className="form-control col-9"
                value={email}
                disabled
                onChange={event => this.handleChangeEmail(event)}
              />
            </div>

            <div className="row mt-3 mr-2">
              <h6 className="col-3">Địa chỉ:</h6>
              <input
                type="text"
                className="form-control col-9"
                value={address}
                disabled
                onChange={event => this.handleChangeAddress(event)}
              />
            </div>

            <div className="row mt-3 mr-2">
              <h6 className="col-3">Tổng giá(Bao gồm phí):</h6>
              <NumericInput disabled className="form-control col-9 disabled-price" value={price} onChange={value => this.handleChangeTotalPrice(value)} />
            </div>

            <div className="row mt-3 mr-2">
              <h6 className="col-3">Phí:</h6>
              <NumericInput disabled className="form-control col-9 disabled-price" value={fee} onChange={value => this.handleChangeFee(value)} />
            </div>
          </div>

          <div className="col-6">
            <div className="row mr-2">
              <h6 className="col-3">CODE:</h6>
              <input
                disabled
                type="text"
                className="form-control col-9"
                value={code}
                onChange={event => this.handleChangeCode(event)}
              />
            </div>

            <div className="row mt-3 mr-2">
              <h6 className="col-3">Phụ trách:</h6>
              <Select value={staffId} style={{ width: 200 }} disabled onChange={this.handleChangeUser}>
                {users.map((item)=> (
                  <Option key={item.id} value={item.id} disabled={item.id === 0 ? true : false}>{item.name}</Option>
                ))}
              </Select>
            </div>

            <div className="row mt-3 mr-2" >
              <h6 className="col-3">Nguồn:</h6>
              <Select value={valueType} className="col-3" disabled onChange={this.handleMenuClick}>
                <Option value="TYPE_WEB">Web</Option>
                <Option value="TYPE_FACEBOOK">Facebook</Option>
                <Option value="TYPE_ZALO">Zalo</Option>
                <Option value="MAIVI">Mai vi</Option>
              </Select>
              <h6 className="ml-4">Trạng thái:</h6>
              <Select value={status} className="col-3 ml-2" disabled onChange={this.handleChangeStatus}>
                <Option disabled value="STATUS_WAIT_HANDLE">Chờ xử lý</Option>
                <Option disabled value="STATUS_WAIT_PAYMENT">Chờ thanh toán</Option>
                <Option disabled value="STATUS_PAYMENT">Đã thanh toán</Option>
                <Option disabled value="STATUS_DEPARTURE">Đã bay chiều đi</Option>
                <Option disabled value="STATUS_DONE">Hoàn thành</Option>
                <Option value="STATUS_CANCEL">Huỷ</Option>
                <Option disabled={status !== "STATUS_WAIT_HANDLE"} value="STATUS_OTHER">Không xuất Tự động</Option>
              </Select>
            </div>

            <div className="row mt-3 mr-2">
              <h6 className="col-3">Thanh toán:</h6>
              <Select value={paymentMethod} onChange={this.handleChangeMethod} disabled style={{ width: 200 }}>
                <Option value="PAYMENT_METHOD_OFFICE">Văn phòng VEGIARE</Option>
                <Option value="PAYMENT_METHOD_HOUSE">Tại nhà</Option>
                <Option value="PAYMENT_METHOD_ONLINE">Cổng thanh toán</Option>
                <Option value="PAYMENT_METHOD_BANK">Chuyển khoản</Option>
                <Option value="PAYMENT_METHOD_WALLET">Ví VEGIARE</Option>
                <Option value="PAYMENT_METHOD_MB_BANK">MB</Option>
              </Select>
            </div>

            <div className="row mt-3 mr-2">
              <h6 className="col-3">Ngân hàng:</h6>
              <input
                type="text"
                className="form-control col-9"
                value={bank}
                disabled
                onChange={event => this.handleChangeBank(event)}
              />
            </div>

            <div className="row mt-3 mr-2">
              <h6 className="col-3">Ngày thanh toán:</h6>
              {payDate !== null? <DatePicker style={{ width: 200 }} value={moment(payDate, dateFormatList)} format={dateFormat} onChange={this.handleChangeBirthday}/> :
              <DatePicker disabled style={{ width: 200 }} format={dateFormat} onChange={this.handleChangeBirthday}/>}
            </div>

            {/* <div className="row mt-3 mr-2">
              <h6 className="col-3">Ghi chú:</h6>
              <input
                type="text"
                className="form-control col-9"
                value={note}
                onChange={event => this.handleChangeNote(event)}
              />
            </div> */}

            {/* <div className="mt-3 text-right">
              <Delay delayInsuranceId={delayInsuranceId} delayInsurance={delayInsurance}></Delay>
            </div> */}
          </div>
        </div>

        <Flight isAddBook={isAdd} data={data} idBook={id} getBook={this.getBookById} flightRep={this.flightRep}/>

        <Customers isAddBook={isAdd} customers={data.customers} idBook={id} getBook={this.getBookById} customersRep={this.customersRep}/>

        {/* <Button type="primary" onClick={this.editBook}> Lưu </Button> */}
      </div>
    );
  }
}
