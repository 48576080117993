import React from "react";
import "./App.css";
import SideNavPage from "./components/slide_nav";
import Home from './page/home';
import Banner from './page/banner';
import Book from './page/bookGlobal';
import BookDetail from './page/bookAirline/detail'
import Groups from './page/bookAirline/groups'
import Notification from './page/bookAirline/notification'
import Country from './page/country';
import CountryDetail from './page/country/detail';
import Province from './page/province';
import ProvinceDetail from './page/province/detail';
import User from './page/user';
import Login from './page/login';
import Blog from "./page/blog";
import Banks from "./page/banks";
import Airline from "./page/airline/index";
import AirlineDetail from "./page/airline/detail";
import AddBlog from "./page/blog/add";
import Template from './page/template'
import PrivateRoute from './components/private_route'
import UserHeader from './components/user'
import Destination from './page/destination'
import DestinationDetail from './page/destination/detail'
import CategoryBlog from './page/category_blog'
import CategoryBlogDetail from './page/category_blog/detail'
import SaleCode from './page/sale code'
import Invite from './page/invite'
import BookInvite from './page/invite/detail'
import CoachInvite from './page/coachInvite/detail'
import Transaction from "./page/Transaction";
import Cookies from 'js-cookie'

import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import * as util from './api/util';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: true,
      user: undefined
    };
  }

  handleToggle = isOpen => {
    this.setState({
      isOpen
    });
  };

  render() {
    const { isOpen } = this.state;
    return (
      <Router>
        <Route
          render={({ location, history }) => (
            <React.Fragment>
              <div className="App">
                <div>
                <Switch>
                  <Route exact path="/login" component={props => <Login />} />
                  <Route exact path="/template" component={props => <Template />} />
                  <div className="row m-0">
                    <div>
                      <SideNavPage
                        onToggle={this.handleToggle}
                        eventKey={selected => {
                          const to = "/" + selected;
                          if (location.pathname !== to) {
                            history.push(to);
                          }
                        }}
                      />
                    </div>
                    <div className="container-fluid p-0" style={{marginLeft: isOpen ? 240 : 64}}>
                      <nav className="navbar navbar-dark bg-dark">
                        <img src={require('./logo.png')} className="d-inline-block align-top ml-2 mr-1" alt="" style={{height: 45}}/>
                        <form className="form-inline my-2 my-lg-0">
                          <UserHeader/>
                        </form>
                      </nav>
                      <main className="container text-left shadow p-3 mb-3 mt-3 bg-white rounded">
                        {/* <PrivateRoute exact path="/" component={props => <Home />} />
                        <PrivateRoute exact path="/home" component={props => <Home />} />
                        <PrivateRoute exact path="/banner" component={props => <Banner />} />
                        <PrivateRoute exact path="/book" component={props => <Book />} />
                        <PrivateRoute exact path="/book/add" component={props => <BookDetail/>} />
                        <PrivateRoute exact path="/book/notification" component={props => <Notification />} />
                        <PrivateRoute exact path="/book/edit/:id" component={props => <BookDetail {...props}/>} />
                        <PrivateRoute exact path="/country" component={props => <Country />} />
                        <PrivateRoute exact path="/country/edit/:code" component={props => <CountryDetail {...props}/>} />
                        <PrivateRoute exact path="/province" component={props => <Province />} />
                        <PrivateRoute exact path="/province/edit/:code" component={props => <ProvinceDetail {...props}/>} />
                        <PrivateRoute exact path="/user/:role" component={props => <User {...props}/>} />
                        <PrivateRoute exact path="/blog/created" component={props => <Blog isCreate={true}/>} />
                        <PrivateRoute exact path="/blog/publish" component={props => <Blog isCreate={false}/>} />
                        <PrivateRoute exact path="/blog/add" component={props => <AddBlog/>} />
                        <PrivateRoute exact path="/blog/edit/:id" component={props => <AddBlog {...props}/>} />
                        <PrivateRoute exact path="/destination" component={props => <Destination/>} />
                        <PrivateRoute exact path="/destination/edit/:id" component={props => <DestinationDetail {...props}/>} />
                        <PrivateRoute exact path="/destination/add" component={props => <DestinationDetail/>} />
                        <PrivateRoute exact path="/category-blog" component={props => <CategoryBlog/>} />
                        <PrivateRoute exact path="/category-blog/detail" component={props => <CategoryBlogDetail {...props}/>} />
                        <PrivateRoute exact path="/banks" component={props => <Banks {...props}/>} />
                        <PrivateRoute exact path="/airline" component={props => <Airline {...props}/>} />
                        <PrivateRoute exact path="/airline/detail/:name" component={props => <AirlineDetail {...props}/>} />
                        <PrivateRoute exact path="/sale" component={props => <SaleCode {...props}/>} />
                        <PrivateRoute exact path="/book/group" component={props => <Groups {...props}/>} />
                        <PrivateRoute exact path="/invite" component={props => <Invite {...props}/>} /> */}
                        <PrivateRoute exact path="/book/edit/:id" component={props => <BookDetail {...props}/>} />
                        <PrivateRoute exact path="/invite/book/:phone" component={props => <BookInvite {...props}/>} />
                        <PrivateRoute exact path="/invite/coach/:phone" component={props => <CoachInvite {...props}/>} />
                        {/* <PrivateRoute exact path="/transaction" component={props => <Transaction />} /> */}
                      </main>
                    </div>
                  </div>
                  </Switch>
                </div>
              </div>
            </React.Fragment>
          )}
        />
      </Router>
    );
  }

  componentWillMount(){
    var data = Cookies.get('user');
    var user = "";
    if(data != '' && data != undefined && data != null){
      user = JSON.parse(data);
      var token = user != null ? user.accessToken : ''
      util.setAuthHeader(token)
      this.setState({
        user
      });
    }
  }
}

export default App;
