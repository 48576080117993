import React from "react";
import SideNav, { NavItem, NavIcon, NavText } from "@trendmicro/react-sidenav";

import * as api from "../../api/user";
import * as fa from "react-icons/fa";
import { GiBank } from "react-icons/gi";
import { MdGroup } from "react-icons/md";
import CONST from "../Const";
import "@trendmicro/react-sidenav/dist/react-sidenav.css";
import "./styles.css";

class SideNavPage extends React.Component {
  state = {
    isOpen: true,
    data: []
  };

  handleToggle = () => {
    var isOpen = !this.state.isOpen;
    this.props.onToggle(isOpen);
    this.setState({
      isOpen
    });
  };

  componentDidMount() {
    api.getRoles().then(response => {
      if (response !== undefined) {
        this.setState({
          data: response
        });
      }
    });
  }

  setTitle(name) {
    if (name === CONST.ROLE_USER) {
      return "Khách hàng";
    } else if (name === CONST.ROLE_ADMIN) {
      return "Quản trị viên";
    } else if (name === CONST.ROLE_CSKH) {
      return "Ticketer";
    } else {
      return "Nội dung";
    }
  }

  renderAdmin = () => {
    return (
      <SideNav.Nav defaultSelected="home">
        <NavItem eventKey="home">
          <NavIcon >
            <fa.FaHome className="Icon fa fa-fw fa-home" />
          </NavIcon>
          <NavText>Home</NavText>
        </NavItem>
        <NavItem eventKey="book">
          <NavIcon>
            <fa.FaHistory className="Icon fa fa-fw fa-home" />
          </NavIcon>
          <NavText>Đơn hàng</NavText>
        </NavItem>
        <NavItem eventKey="transaction">
          <NavIcon>
            <fa.FaMoneyCheckAlt className="Icon fa fa-fw fa-home" />
          </NavIcon>
          <NavText>Giao dịch ngân hàng</NavText>
        </NavItem>
        <NavItem eventKey="banner">
          <NavIcon>
            <fa.FaBloggerB className="Icon fa fa-fw fa-home" />
          </NavIcon>
          <NavText>Banner</NavText>
        </NavItem>
        <NavItem eventKey="invite">
          <NavIcon>
            <MdGroup className="Icon fa fa-fw fa-home" />
          </NavIcon>
          <NavText>Người giới thiệu</NavText>
        </NavItem>
        <NavItem eventKey="category-blog">
          <NavIcon>
            <fa.FaCuttlefish className="Icon fa fa-fw fa-home" />
          </NavIcon>
          <NavText>Danh mục blog</NavText>
        </NavItem>
        <NavItem eventKey="blog">
          <NavIcon>
            <fa.FaBloggerB className="Icon fa fa-fw fa-home" />
          </NavIcon>
          <NavText>Blog</NavText>
            <NavItem key={0} eventKey={`blog/created`}>
              <NavText>Mới tạo</NavText>
            </NavItem>
            <NavItem key={1} eventKey={`blog/publish`}>
              <NavText>Đã được phê duyệt</NavText>
            </NavItem>
        </NavItem>
        <NavItem eventKey="sale">
          <NavIcon>
            <fa.FaHistory className="Icon fa fa-fw fa-home" />
          </NavIcon>
          <NavText>Mã khuyến mãi</NavText>
        </NavItem>
        <NavItem eventKey="destination">
          <NavIcon>
            <fa.FaPlane className="Icon fa fa-fw fa-home" />
          </NavIcon>
          <NavText>Địa diểm cần đến</NavText>
        </NavItem>
        <NavItem eventKey="country">
          <NavIcon>
            <fa.FaCity className="Icon fa fa-fw fa-home" />
          </NavIcon>
          <NavText>Đất nước</NavText>
        </NavItem>
        <NavItem eventKey="province">
          <NavIcon>
            <fa.FaCity className="Icon fa fa-fw fa-home" />
          </NavIcon>
          <NavText>Thành phố</NavText>
        </NavItem>
        <NavItem eventKey="banks">
          <NavIcon>
            <GiBank className="Icon fa fa-fw fa-home" />
          </NavIcon>
          <NavText>Quản lý Banks</NavText>
        </NavItem>
        <NavItem eventKey="airline">
          <NavIcon>
            <fa.FaPlane className="Icon fa fa-fw fa-home" />
          </NavIcon>
          <NavText>Quản lý Airline</NavText>
        </NavItem>
        <NavItem eventKey="user">
          <NavIcon>
            <fa.FaUserAlt className="Icon fa fa-fw fa-home" />
          </NavIcon>
          <NavText>Tài khoản</NavText>
          {this.state.data.map(item => (
            <NavItem key={item.id} eventKey={`user/${item.id}`}>
              <NavText>{item.nameDisplay}</NavText>
            </NavItem>
          ))}
        </NavItem>
      </SideNav.Nav>
    );
  };

  renderContent = () => {
    return (
      <SideNav.Nav defaultSelected="home">
        <NavItem eventKey="banner">
          <NavIcon>
            <fa.FaBloggerB className="Icon fa fa-fw fa-home" />
          </NavIcon>
          <NavText>Banner</NavText>
        </NavItem>
        <NavItem eventKey="blog">
          <NavIcon>
            <fa.FaBloggerB className="Icon fa fa-fw fa-home" />
          </NavIcon>
          <NavText>Blog</NavText>
          <NavItem key={0} eventKey={`blog/created`}>
              <NavText>Mới tạo</NavText>
            </NavItem>
            <NavItem key={1} eventKey={`blog/publish`}>
              <NavText>Đã được phê duyệt</NavText>
            </NavItem>
        </NavItem>
        <NavItem eventKey="country">
          <NavIcon>
            <fa.FaCity className="Icon fa fa-fw fa-home" />
          </NavIcon>
          <NavText>Đất nước</NavText>
        </NavItem>
        <NavItem eventKey="province">
          <NavIcon>
            <fa.FaCity className="Icon fa fa-fw fa-home" />
          </NavIcon>
          <NavText>Thành phố</NavText>
        </NavItem>
      </SideNav.Nav>
    );
  };

  renderTicketer = () => {
    return (
      <SideNav.Nav defaultSelected="home">
        <NavItem eventKey="book">
          <NavIcon>
            <fa.FaHistory className="Icon fa fa-fw fa-home" />
          </NavIcon>
          <NavText>Đơn hàng</NavText>
        </NavItem>
      </SideNav.Nav>
    );
  };

  renderAgent = () => {
    var code = localStorage.getItem("agent");
    return (
      <SideNav.Nav defaultSelected={`invite/book/${code}`}>
        <NavItem eventKey={`invite/book/${code}`}>
          <NavIcon>
            <fa.FaHistory className="Icon fa fa-fw fa-home" />
          </NavIcon>
          <NavText>Đơn hàng vé máy bay</NavText>
        </NavItem>
        <NavItem eventKey={`invite/coach/${code}`}>
          <NavIcon>
            <fa.FaHistory className="Icon fa fa-fw fa-home" />
          </NavIcon>
          <NavText>Đơn hàng vé xe</NavText>
        </NavItem>
      </SideNav.Nav>
    );
  };

  renderRole = () => {
    // var data = localStorage.getItem("user");
    // var role = CONST.ROLE_CONTENT;
    // if (data !== null && data !== "") {
    //   var user = JSON.parse(data);
    //   role = user.user.roles[0].name;
    // }
    // if (role === CONST.ROLE_ADMIN) {
    //   return this.renderAdmin();
    // }
    // if (role === CONST.ROLE_CONTENT) {
    //   return this.renderContent();
    // } else if (role === CONST.ROLE_INVITE) {
    //   return this.renderAgent();
    // } else if (role === CONST.ROLE_CONTENT) {
    //   return this.renderContent();
    // } else return this.renderTicketer();

    return this.renderAgent();
  };

  render() {
    const { isOpen } = this.state;
    return (
      <SideNav
        className="Nav anyClass"
        expanded={isOpen}
        onToggle={this.handleToggle}
        onSelect={this.props.eventKey}
      >
        <SideNav.Toggle />
        {this.renderRole()}
      </SideNav>
    );
  }
}

export default SideNavPage;
