import axios from "axios";
// const BASE_URL = "http://localhost:8080/api/v1/cms/";
const BASE_URL = "https://www.api.vegiare.com/api/v1/cms/";
// const BASE_URL = "https://devapi.vegiare.com/api/v1/cms/";
export const instance = axios.create({
  baseURL: BASE_URL,
  timeout: 300000,
});

export const setAuthHeader = (token) => {
  instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  instance.defaults.headers.common['Access-Control-Allow-Origin'] = `*`;
}

export function checkError(err){
  if (err.response.status === 401) {
    alert(err.response.data.message)
    localStorage.clear();
  }else if (err.response.status === 400) {
    alert(err.response.data.message)
  }else
  alert(err.message)
}