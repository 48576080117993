import * as util from '../util'
const instance = util.instance

export function getBooks(page, phoneInvite) {
  return instance
    .get(`coach?page=${page}&phoneInvite=${phoneInvite}`)
    .then(response => {
      return response.data;
    }).catch(util.checkError);
}

export function getBookById(id) {
  return instance
    .get(`coach/${id}`)
    .then(response => {
      return response.data;
    }).catch(util.checkError);
}

export function issueTicket(obj){
  return instance
    .post(`coach/issueTicket`, obj)
    .then(response => {
      return response.data;
    }).catch(util.checkError);
}