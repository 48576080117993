import React from "react";
import * as api from "../../api/book_airline";
import * as apiUser from "../../api/user";
import "./styles.css";
import ReactUltimatePagination from "react-ultimate-pagination-bootstrap-4";
import { Link } from "react-router-dom";
import { Select, Icon, Button, Modal, Input, Form, } from 'antd';
const confirm = Modal.confirm;
const { Option } = Select;
var moment = require('moment');
const dateFormatList = ['DD/MM/YYYY', `YYYY-MM-DDTHH:mm:ss.SSSZ`];

class Book extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      page: 1,
      totalPages: 1,
      staffId: 0,
      users: [],
      valueType: null, 
      status: null,
      search: "",
      modalIsOpen: false,
      airlineCode: "VN"
    };
  }

  componentDidMount() {
    this.getBooks(0)
    this.getStaff()
  }

  getBooks(page){
    api.getBooks(page).then(response => {
      if (response !== undefined) {
        this.setState({
          data: response.content,
          page: page === 0 && response.content.length === 0 ? response.pageable.pageNumber : response.pageable.pageNumber + 1,
          totalPages: response.totalPages
        });
      }
    });
  }

  getStaff(){
    apiUser.getAsign().then(response => {
      if (response !== undefined) {
        var users = response;
        var user = {id: 0, name: "-- chọn --"};
        users.splice(0, 0, user);
        this.setState({
          users,
        });
      }
    });
  }

  onPageChange = page => {
    this.setState({ page }, () => {
      api.getBooks(this.state.page - 1).then(response => {
        if (response !== undefined) {
          this.setState({
            data: response.content,
            page: response.pageable.pageNumber + 1
          });
        }
      });
    });
  };

  showDeleteConfirm(id) {
    confirm({
      title: 'Cảnh báo!',
      content: 'Bạn có chắc chắn muốn xoá?',
      okText: 'Có',
      okType: 'danger',
      cancelText: 'Không',
      onOk:()=> {
        this.deleteBook(id)
      },
      onCancel() {
        
      },
    });
  }

  deleteBook = id => {
    api.deleteBook(id).then(response => {
      if (response !== undefined) {
        this.getBooks(0)
        alert(response.message);
      }
    });
  };

  renderItem(item) {
    const STATUS_WAIT_HANDLE="Chờ xử lý"
    const STATUS_WAIT_PAYMENT = "Chờ thanh toán"
    const STATUS_PAYMENT ="Đã thanh toán"
    const STATUS_CANCEL = "Huỷ"
    const STATUS_DONE = "Hoàn thành"
    const STATUS_OTHER = "Khác"

    var status = "Chờ xác nhận"
    if(item.status === "STATUS_WAIT_HANDLE"){
      status = STATUS_WAIT_HANDLE
    }else if(item.status === "STATUS_WAIT_PAYMENT"){
      status = STATUS_WAIT_PAYMENT
    } else if(item.status === "STATUS_PAYMENT"){
      status = STATUS_PAYMENT
    } else if(item.status === "STATUS_CANCEL"){
      status = STATUS_CANCEL
    } else if(item.status === "STATUS_DONE"){
      status = STATUS_DONE
    } else status = STATUS_OTHER
    return (
      <tr key={item.id} className="row m-0">
        <td className="align-middle col-2">{moment(item.createdAt, dateFormatList[1]).format('HH:mm')}<br></br>{moment(item.createdAt, dateFormatList[1]).format('DD/MM/YYYY')}</td>
        <td className="align-middle col-2">{item.code}</td>
        <td className="align-middle col-1">{item.itineraryType === 1 ? <div>{item.departureCode}</div>: <div>{item.departureCode} <br></br> {item.returnCode}</div>}</td>
        <td className="align-middle col-1">{item.itineraryType === 1 ? 'Một chiều' : 'Khứ hồi'}</td>
        <td className="align-middle col-2">{item.contact.lastName + ' ' +item.contact.firstName} <br></br> {item.contact.phone}</td>
        <td className="align-middle col-1">{item.totalPrice.toLocaleString(navigator.language, { minimumFractionDigits: 0 })} VND</td>
        <td className="align-middle col-1">{item.departureAirportCode+'-'+item.destinationAirportCode}</td>
        <td className="align-middle col-1">{status}</td>
        <td className="align-middle col-1">
          <Link to={"/book/edit/" + item.code}>
            <Icon type="edit" style={{color:"black"}}/>
          </Link>
          {/* <br/>
          <Icon type="delete" onClick={() => {this.showDeleteConfirm(item.id)}}/> */}
        </td>
      </tr>
    );
  }

  handleChangeUser = event => {
    this.setState({ staffId: event });
  };

  handleChangeStatus = event => {
    this.setState({ status: event});
  };

  handleSource =(e) => {
    this.setState({valueType: e})
  }

  handleAirlineCode =(e) => {
    this.setState({valueType: e})
  }

  searchBook=()=>{
    const { valueType, status, staffId, search} = this.state;
    var page = 0;
    api.searchBook(valueType, status, staffId, page, search).then(response => {
      if (response !== undefined) {
        this.setState({
          data: response.content,
          page: page === 0 && response.content.length === 0 ? response.pageable.pageNumber : response.pageable.pageNumber + 1,
          totalPages: response.totalPages
        });
      }
    });
  }

  searchChange=(event)=>{
    var search = event.target.value
    this.setState({ search });
  }

  returnTicket=(e)=>{
    e.preventDefault();
		this.props.form.validateFields((err, values) => {
			if (!err) {
        var obj = {code: values["code"], airlineCode: this.state.airlineCode}
        api.returnTicket(obj).then(response => {
          if (response !== undefined) {
            alert(response.message);
          }
        });
			}
		});
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { valueType, status, users, staffId} = this.state;
    return (
      <div className="container text-center">
        <Modal
					visible={this.state.modalIsOpen}
					onCancel={()=>{this.setState({modalIsOpen: false})}}
					width={300}
					footer={[
					<Button key="submit" className="mr-2" onClick={(e)=>this.returnTicket(e)}>
						Xuất vé
					</Button>,
					<Button key="submit" onClick={()=>{this.setState({modalIsOpen: false})}}>
						Huỷ
					</Button>
					]}
				>
					<Form >
						<div className="mt-3">
							<Form.Item className="mb-2">
								<span >Mã giữ chỗ</span><br/>
								{getFieldDecorator('code', {
									rules: [{ required: true, message: 'Mã giữ chỗ không được để trống' }],
								})(
									<Input/>,
								)}
							</Form.Item>
              {/* <span >Hãng hàng không</span><br/>
              <Select value={this.state.airlineCode} className="select-view mt-2" onChange={this.handleAirlineCode}>
                <Option value="VJ">Vietjet</Option>
                <Option value="JQ">JetStar</Option>
                <Option value="QH">Bamboo</Option>
                <Option value="VN">VietnamAirline</Option>
              </Select> */}
						</div>
					</Form>
				</Modal>
        <div className="text-right">
          {/* <Button type="primary" onClick={()=>this.setState({modalIsOpen: true})}>Xuất vé</Button> */}
          {/* <Link className="ml-5" to="/book/add">
            <Button type="primary" >+ Thêm</Button>
          </Link> */}
        </div>
        <div className="text-right mt-2">
            <span className="mr-2 ml-2">Phụ trách:</span>
            <Select value={staffId} className="select-view" onChange={this.handleChangeUser}>
              {users.map((item)=> (
                <Option key={item.id} value={item.id} disabled={item.id === 0 ? true : false}>{item.name}</Option>
              ))}
            </Select>

            <span className="mr-2 ml-2">Nguồn:</span>
            <Select value={valueType} className="select-view" onChange={this.handleSource}>
              <Option value={null} disabled>-- chọn --</Option>
              <Option value="TYPE_WEB">Web</Option>
              <Option value="TYPE_FACEBOOK">Facebook</Option>
              <Option value="TYPE_ZALO">Zalo</Option>
              <Option value="MAIVI">Mai vi</Option>
            </Select>

            <span className="mr-2 ml-2">Trạng thái:</span>
            <Select value={status} className="select-view" onChange={this.handleChangeStatus}>
              <Option value={null} disabled>-- chọn --</Option>
              <Option value="STATUS_WAIT_HANDLE">Chờ xử lý</Option>
              <Option value="STATUS_WAIT_PAYMENT">Chờ thanh toán</Option>
              <Option value="STATUS_PAYMENT">Đã thanh toán</Option>
              <Option value="STATUS_CANCEL">Huỷ</Option>
              <Option value="STATUS_DONE">Hoàn thành</Option>
              <Option value="STATUS_OTHER">Khác</Option>
            </Select>

          
          <Input
            style={{width: 150, marginRight: 10, marginLeft: 10}}
            type="search"
            placeholder="Tìm kiếm"
            aria-label="Search"
            value={this.state.search}
            onChange={this.searchChange}
          />
          <Button type="primary" onClick={this.searchBook}>Tìm kiếm</Button>
        </div>
        <table className="table mt-3 table-hover table-bordered">
          <thead className="thead-dark">
            <tr className="row m-0">
              <th className="col-2">Ngày đặt</th>
              <th className="col-2">Mã đơn hàng</th>
              <th className="col-1">Code</th>
              <th className="col-1">Loại vé</th>
              <th className="col-2">Tên</th>
              <th className="col-1">Tổng tiền</th>
              <th className="col-1">Hành trình</th>
              <th className="col-1">Status</th>
              <th className="col-1">Hành động</th>
            </tr>
          </thead>
          <tbody>{this.state.data.map(item => this.renderItem(item))}</tbody>
        </table>
        <div style={{display: this.state.data.length !== 0 ? 'block' : 'none' }}>
          <ReactUltimatePagination
            currentPage={this.state.page}
            totalPages={this.state.totalPages}
            onChange={this.onPageChange}
          />
        </div>
      </div>
    );
  }
}
export default Form.create()(Book);
