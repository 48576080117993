import React from "react";
import "./styles.css";
import * as apiBook from "../../api/book_airline";
import NumericInput from '../../components/inputtext_number'
import { Icon, Button, DatePicker, Modal, Radio } from 'antd';
import SearchInputDeparture from '../../components/searchInput' 
const { RangePicker } = DatePicker;
const confirm = Modal.confirm;
var moment = require('moment');

const dateFormatList = ['DD/MM/YYYY', `YYYY-MM-DDTHH:mm:ss.SSSZ`];

const NO_DISABLE = "";
const DISABLE = "disabled-div";
const ONE_AWAY = 1;
const ROUND_TRIP = 2;

export default class flight extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {}, 
      disable: NO_DISABLE
    };
  }

  componentDidMount() {
    
  }

  componentWillReceiveProps(nextProps) {
    if (typeof nextProps.data != "undefined" && nextProps.data.departureAirlineCode !== undefined) {
      this.setState({data: nextProps.data, id: nextProps.idBook})
      this.getBook(nextProps.idBook)
    }else{
    }
  }

  getBook(id){
    apiBook.getBookById(id).then(response => {
      if (response !== undefined) {
        this.setState({
          data: response,
        });
      }
    });
  }

  showDeleteFlightConfirm() {
    confirm({
      title: 'Cảnh báo!',
      content: 'Bạn có chắc chắn muốn xoá?',
      okText: 'Có',
      okType: 'danger',
      cancelText: 'Không',
      onOk() {
        
      },
      onCancel() {
        
      },
    });
  }


  openModalFlight = () => {
    const {data, disable} = this.state
    var disable_div = disable
    var itineraryType = data.itineraryType
    if(itineraryType === ONE_AWAY){
      disable_div = DISABLE;
    }else{
      disable_div = NO_DISABLE;
    }
    this.setState({ 
      departureAirportCode: data.departureAirportCode, 
      destinationAirportCode: data.destinationAirportCode,
      departureDate: data.departureDate, 
      departureLandDate: data.departureLandDate,
      returnDate: data.returnDate, 
      returnLandDate: data.returnLandDate,
      departureAirlineCode: data.departureAirlineCode, 
      departurePriceTotal: data.departurePriceTotal,
      departureFeeTotal: data.departureFeeTotal, 
      departureFlightNumber: data.departureFlightNumber,
      returnAirlineCode: data.returnAirlineCode, 
      returnPriceTotal: data.returnPriceTotal,
      returnFeeTotal: data.returnFeeTotal, 
      returnFlightNumber: data.returnFlightNumber,
      departureName: data.departureName,
      returnName: data.returnName,
      itineraryType,
      modalIsOpenCustomer: true,
      disable: disable_div
    });
  }

  addFlight=()=>{
    this.setState({ 
      departureAirportCode: "", 
      destinationAirportCode: "",
      departureDate: moment().format(dateFormatList[1]), 
      departureLandDate: moment().format(dateFormatList[1]),
      returnDate: moment().format(dateFormatList[1]),
      returnLandDate: moment().format(dateFormatList[1]),
      departureAirlineCode: "", 
      departurePriceTotal: "",
      departureFeeTotal: "", 
      departureFlightNumber: "",
      returnAirlineCode: "", 
      returnPriceTotal: "",
      returnFeeTotal: "", 
      returnFlightNumber: "",
      itineraryType: 1,
      modalIsOpenCustomer: true,
      disable: DISABLE
    });
  }

  closeModalCustomer = () => {
    this.setState({ modalIsOpenCustomer: false });
  }

  handleChangeDepartureAirportCode = (value, text) => {
    this.setState({ departureAirportCode: value, departureName: text });
  };
  handleChangeDepartureAirlineCode = (event) => {
    this.setState({ departureAirlineCode: event.target.value });
  };

  handleChangeDestinationAirportCode = (value, text) => {
    this.setState({ destinationAirportCode: value, departureName: text});
  };

  handleChangeReturnAirlineCode = (event) => {
    this.setState({ returnAirlineCode: event.target.value });
  };

  handleChangeDepartureDate = (date, dateString) => {
    if(date != null)
      this.setState({ departureDate: date.format(dateFormatList[1]) });
  };

  handleChangeReturnDate = (date, dateString) => {
    if(date != null)
      this.setState({ returnDate: date.format(dateFormatList[1]) });
  };

  handleChangePeople = (event) => {
    this.setState({ people: event.target.value });
  };

  handleChangeDeparturePriceTotal = (value) => {
    this.setState({ departurePriceTotal: value });
  };

  handleChangeReturnPriceTotal = (value) => {
    this.setState({ returnPriceTotal: value });
  };

  handleChangeDepartureFeeTotal = (value) => {
    this.setState({ departureFeeTotal: value });
  };

  handleChangeReturnFeeTotal = (value) => {
    this.setState({ returnFeeTotal: value });
  };

  handleChangeDepartureFlightNumber = (event) => {
    this.setState({ departureFlightNumber: event.target.value });
  };

  handleChangeReturnFlightNumber = (event) => {
    this.setState({ returnFlightNumber: event.target.value });
  };

  handleChangeDatePayment = (date, dateString) => {
    if(date != null)
      this.setState({ date: date.format(dateFormatList[1]) });
  };

  onChangeDateTimeDeparture(value, dateString) {
    console.log('Selected Time: ', value);
    console.log('Formatted Selected Time: ', dateString);
  }
  
  onOkOneWay = (value) => {
    if(value != null){
      var departureLandDate = value[1].format(dateFormatList[1]);
      if(moment(departureLandDate).isAfter(this.state.returnDate) === true){
        this.setState({ returnDate: departureLandDate, returnLandDate: departureLandDate, departureDate: value[0].format(dateFormatList[1]), departureLandDate });
      }else {
        this.setState({ departureDate: value[0].format(dateFormatList[1]), departureLandDate });
      }
    }
  }

  onOkRoundTrip = (value) => {
    if(value != null){
      var returnDate = value[0].format(dateFormatList[1]);
      if(moment(this.state.departureLandDate).isAfter(returnDate)){
        this.setState({ departureDate: returnDate, departureLandDate: returnDate, returnDate: returnDate, returnLandDate: value[1].format(dateFormatList[1]) });
      }else {
        this.setState({ returnDate: returnDate, returnLandDate: value[1].format(dateFormatList[1]) });
      }
      
    }
  }

  onChange = e => {
    var disable = this.state.disable;
    var itineraryType = e.target.value;
    if(itineraryType === ONE_AWAY){
      disable = DISABLE;
    }else{
      disable = NO_DISABLE;
    }
    this.setState({itineraryType, disable});
  };

  updateBook=()=>{
    const { 
      id,
      departureAirportCode, 
      destinationAirportCode, 
      departureDate, 
      departureLandDate,
      returnDate, 
      returnLandDate,
      departureAirlineCode, 
      departurePriceTotal, 
      departureFeeTotal, 
      departureFlightNumber,
      returnAirlineCode, 
      returnPriceTotal,
      returnFeeTotal, 
      returnFlightNumber,
      itineraryType
    } = this.state

    var data = { 
      departureAirportCode, 
      destinationAirportCode, 
      departureDate, 
      departureLandDate,
      returnDate, 
      returnLandDate,
      departureAirlineCode, 
      departurePriceTotal, 
      departureFeeTotal, 
      departureFlightNumber,
      returnAirlineCode, 
      returnPriceTotal,
      returnFeeTotal, 
      returnFlightNumber,
      itineraryType,
      customers: []
    }

    if(departureAirportCode===""){
      alert("Điểm đi không để trống")
      return;
    }

    if(destinationAirportCode===""){
      alert("Điểm đến không để trống")
      return;
    }

    if(departureAirlineCode===""){
      alert("Hãng hàng không chiều đi không để trống")
      return;
    }

    if(departurePriceTotal===""){
      alert("Giá chiều đi không để trống")
      return;
    }

    if(departureFeeTotal===""){
      alert("Phí chiều đi không để trống")
      return;
    }

    if(departureFlightNumber===""){
      alert("Mã chuyến bay chiều đi không để trống")
      return;
    }

    if(itineraryType === 2){
      if(returnAirlineCode===""){
        alert("Hãng hàng không chiều về không để trống")
        return;
      }
  
      if(returnPriceTotal===""){
        alert("Giá chiều về không để trống")
        return;
      }
  
      if(returnFeeTotal===""){
        alert("Phí chiều về không để trống")
        return;
      }
  
      if(returnFlightNumber===""){
        alert("Mã chuyến bay chiều về không để trống")
        return;
      }
    }

    if(this.props.isAddBook){
      this.props.flightRep(data)
      this.setState({data: data, modalIsOpenCustomer: false})
    }else{
      apiBook.editFlight(id, data).then(response => {
        if (response !== undefined) {
          this.props.getBook()
          this.setState({modalIsOpenCustomer: false})
          alert(response.message);
        }
      });
    }
    
  }

  renderFlight() {
    const {data} = this.state
    // console.log(JSON.stringify(data))
    if(data.departureAirlineCode !== undefined){
    return (
      <tbody>
        <tr className="row m-0">
          <th className="align-middle col-2">{`${data.departureAirlineCode}(${data.departureFlightNumber})`}</th>
          <th className="align-middle col-3">{data.departureName} <br></br>{`${data.departureAirportCode}(${moment(data.departureDate, dateFormatList[1]).format('HH:mm-MM/DD/YYYY')})`}</th>
          <th className="align-middle col-3">{data.returnName} <br></br>{`${data.destinationAirportCode}(${moment(data.departureLandDate, dateFormatList[1]).format('HH:mm-MM/DD/YYYY')})`}</th>
          {/* <th className="align-middle col-2">{data.customers.length}</th> */}
          <th className="align-middle col-3">{data.departurePriceTotal.toLocaleString(navigator.language, { minimumFractionDigits: 0 })} VND</th>
          {/* <th className="align-middle col-2">
            <Icon type="edit" onClick={() => {this.openModalFlight(true)}}/>
          </th> */}
        </tr>
        {this.renderReturn(data)}
      </tbody>
    );
    }
  }

  renderReturn(data){
    if(data.itineraryType === 2){
      return(
        <tr className="row m-0">
            <th className="align-middle col-2">{`${data.returnAirlineCode}(${data.returnFlightNumber})`}</th>
            <th className="align-middle col-3">{data.returnName} <br></br>{`${data.destinationAirportCode}(${moment(data.returnDate).format('HH:mm-MM/DD/YYYY')})`}</th>
            <th className="align-middle col-3">{data.departureName} <br></br>{`${data.departureAirportCode}(${moment(data.returnLandDate).format('HH:mm-MM/DD/YYYY')})`}</th>
            {/* <th className="align-middle col-2">{data.customers.length}</th> */}
            <th className="align-middle col-2">{data.returnPriceTotal.toLocaleString(navigator.language, { minimumFractionDigits: 0 })} VND</th>
            {/* .toLocaleString() */}
            <th className="align-middle col-2">
              <Icon type="edit" onClick={() => {this.openModalFlight(false)}}/>
            </th>
          </tr>
      );
    }else{
      return null;
    }
  }

  renderAdd(){
    if(this.props.isAddBook){
      return(
        <div className="ml-auto">
          <Button type="primary" onClick={this.addFlight}> Thêm mới </Button>
        </div>
      );
    }
  }

  render() {
    const { 
      departureAirportCode, 
      destinationAirportCode, 
      departureDate, 
      departureLandDate, 
      returnDate, 
      returnLandDate, 
      departureAirlineCode, 
      departurePriceTotal, 
      departureFeeTotal, 
      departureFlightNumber,
      returnAirlineCode, 
      returnPriceTotal,
      returnFeeTotal, 
      returnFlightNumber,
      itineraryType,
      disable,
      departureName,
      returnName
    } = this.state;
    return (
      <div>
        <div className="row mt-3 d-flex pl-3 pr-3 align-self-center">
        <h6>THÔNG TIN CHUYẾN BAY:</h6>

        {this.renderAdd()}
        </div>
        <table className="table table-hover table-bordered">
          <thead className="thead-dark">
            <tr className="row m-0">
              <th className="col-3">HÀNG KHÔNG</th>
              <th className="col-3">ĐIỂM ĐI</th>
              <th className="col-3">ĐIỂM TỚI</th>
              {/* <th className="col-2">SỐ LƯỢNG KHÁCH</th> */}
              <th className="col-3">TỔNG GIÁ</th>
              {/* <th className="align-middle col-2">HÀNH ĐỘNG</th> */}
            </tr>
          </thead>
            {this.renderFlight()}
        </table>

        <Modal
          width={800}
          visible={this.state.modalIsOpenCustomer}
          confirmLoading={false}
          onCancel={this.closeModalCustomer}
          footer={[
            <Button key="back" onClick={this.closeModalCustomer}>
              Huỷ
            </Button>,
            <Button key="submit" type="primary" loading={this.state.loading} onClick={this.updateBook}>
              Lưu
            </Button>
          ]}
        >
        <div className="row mt-3">
          <div className="col-6">
          <h4>Tìm kiếm</h4>
          <h6>Điểm đi</h6>
          <SearchInputDeparture placeholder="" style={{ width: 300 }} value={this.handleChangeDepartureAirportCode}>{departureAirportCode}</SearchInputDeparture>

          <h6 className="mt-3">Điểm đến:</h6>
          <SearchInputDeparture placeholder="" style={{ width: 300 }} value={this.handleChangeDestinationAirportCode}>{destinationAirportCode}</SearchInputDeparture>

          <h6 className="mt-3">Ngày đi:</h6>
          <RangePicker
            className="mb-3"
            showTime={{ format: 'HH:mm' }}
            format="YYYY-MM-DD HH:mm"
            placeholder={['Start Time', 'End Time']}
            onOk={this.onOkOneWay}
            allowClear={false}
            defaultValue={[moment(departureDate, dateFormatList), moment(departureLandDate, dateFormatList)]}
            onChange={(dates)=> this.onOkOneWay(dates)}
            value={[moment(departureDate, dateFormatList), moment(departureLandDate, dateFormatList)]}
          />
          
          <div className={disable}>
            <h6 >Ngày về:</h6>
            <RangePicker
              showTime={{ format: 'HH:mm' }}
              format="YYYY-MM-DD HH:mm"
              placeholder={['Start Time', 'End Time']}
              onOk={this.onOkRoundTrip}
              allowClear={false}
              defaultValue={[moment(returnDate, dateFormatList), moment(returnLandDate, dateFormatList)]}
              onChange={(dates)=> this.onOkRoundTrip(dates)}
              value={[moment(returnDate, dateFormatList), moment(returnLandDate, dateFormatList)]}
            />
          </div>
          <h6 className="mt-3">Loại vé:</h6>
          <Radio.Group onChange={this.onChange} value={itineraryType}>
            <Radio value={ONE_AWAY}>1 chiều</Radio>
            <Radio value={ROUND_TRIP}>Khứ hồi</Radio>
          </Radio.Group>
          </div>

          <div className="col-3">
          <h4>Vé đi</h4>
          <h6>Hãng hàng không: </h6>
          <textarea
            rows="1"
            className="form-control mb-3"
            value={departureAirlineCode}
            onChange={this.handleChangeDepartureAirlineCode}
          />

          <h6>Giá(Bao gồm phí): </h6>
          <NumericInput className="form-control mb-3" value={departurePriceTotal} onChange={this.handleChangeDeparturePriceTotal} />


          <h6>Phí:</h6>
          <NumericInput className="form-control mb-3" value={departureFeeTotal} onChange={this.handleChangeDepartureFeeTotal}/>

          <h6 className="mt-3">Mã chuyến bay:</h6>
          <textarea
            rows="1"
            className="form-control mb-3"
            value={departureFlightNumber}
            onChange={this.handleChangeDepartureFlightNumber}
          />
          </div>

          <div className={disable + " col-3"}>
          <h4>Vé về</h4>
          <h6>Hãng hàng không: </h6>
          <textarea
            rows="1"
            className="form-control mb-3"
            value={returnAirlineCode}
            onChange={this.handleChangeReturnAirlineCode}
          />
          <h6>Giá(Bao gồm phí): </h6>
          <NumericInput className="form-control mb-3" value={returnPriceTotal} onChange={this.handleChangeReturnPriceTotal} />

          <h6>Phí:</h6>
          <NumericInput className="form-control mb-3" value={returnFeeTotal} onChange={this.handleChangeReturnFeeTotal} />

          <h6 className="mt-3">Mã chuyến bay:</h6>
          <textarea
            rows="1"
            className="form-control mb-3"
            value={returnFlightNumber}
            onChange={this.handleChangeReturnFlightNumber}
          />
          </div>
          </div>
        </Modal>
      </div>
    );
  }
}
